import React, { useState } from 'react';
import { Box, useBreakpointValue, useTheme } from "@chakra-ui/react";
import Lottie from "lottie-react";
import { motion } from 'framer-motion';
import animationData from '../../assets/intro-lottie.json';
import { useAnimationContext } from '../../contexts/AnimationProvider';

const MotionBox = motion(Box);

const IntroAnimation = ({ onComplete }: { onComplete?: () => void }) => {
  const theme = useTheme();
  const [animationComplete, setAnimationComplete] = useState(false);

  const handleAnimationComplete = () => {
    setAnimationComplete(true);
  };

  return (
    <MotionBox
      initial={{ opacity: 1 }}
      animate={{ opacity: animationComplete ? 0 : 1 }}
      transition={{ duration: 2 }}
      bgColor="white"
      position="fixed"
      top={0}
      right={0}
      bottom={0}
      left={0}
      zIndex={9999}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 8 }}
        transition={{ duration: 2, delay: 3.16 }}
        style={{
          position: "absolute",
          backgroundColor: theme.colors.bg[900],
          borderRadius: "50%",
          width: "50vw",
          height: "50vw"
        }}
        onAnimationComplete={onComplete}
      />
      <Lottie
        autoplay
        animationData={animationData}
        loop={false}
        height="60vh"
        width="60vh"
        onLoopComplete={handleAnimationComplete}
      />
    </MotionBox>
  );
};

export default IntroAnimation;
