import * as React from 'react';
import theme from './theme';
import { Box, ChakraProvider, useColorMode } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useEffect } from 'react';
import IntroAnimation from './components/IntroAnimation/IntroAnimation';
import { motion } from 'framer-motion';
import { Analytics } from '@vercel/analytics/react';
import { AnimationProvider, useAnimationContext } from './contexts/AnimationProvider';

const MotionBox = motion(Box);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
        },
    },
})

interface AppProps { }

const Content: React.FC<React.PropsWithChildren<AppProps>> = ({ children }) => {
    const { setAnimationFinished } = useAnimationContext();
    const [showContent, setShowContent] = React.useState(false);

    const handleAnimationComplete = () => {
        setShowContent(true);
        setAnimationFinished(true);

        // @ts-ignore
        window.dataLayer = window.dataLayer || [];

        // @ts-ignore
        function gtag() { window.dataLayer.push(arguments); }

        // @ts-ignore
        gtag('event', 'animation_loaded');
    };

    return (
        <>
            <MotionBox
                minHeight="100vh"
                position="relative"
                initial={{ opacity: 0 }}
                animate={{ opacity: showContent ? 1 : 0 }}
                transition={{ duration: 0.8, ease: "easeInOut" }}
            >
                {children}
            </MotionBox>
            {!showContent && <IntroAnimation onComplete={handleAnimationComplete} />}
        </>
    )
}

const App: React.FC<React.PropsWithChildren<AppProps>> = ({ children }) => {
    const { setColorMode } = useColorMode();

    useEffect(() => setColorMode('dark'));

    return (
        <AnimationProvider>
            <ChakraProvider resetCSS theme={theme}>
                <Analytics />
                <QueryClientProvider client={queryClient}>
                    <Content>
                        {children}
                    </Content>
                </QueryClientProvider>
            </ChakraProvider>
        </AnimationProvider>
    );
};

export default App;
